<template>
  <v-dialog v-model="value" @input="close" max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline">{{$t('profile.main.dialogTitle')}}</span>
      </v-card-title>
      <v-card-text>
        <v-row no-gutters>

          <v-col cols="12" class="mt-6">
            <v-form ref="form" lazy-validation>
              <v-row no-gutters>

                <!-- Firstname -->
                <v-col cols="12" md="6">
                  <v-text-field
                    :label="$t('profile.main.form.firstname')"
                    v-model="form.person.firstname"
                    outlined
                    required
                    :maxlength="255"/>
                </v-col>

                <!-- Name -->
                <v-col cols="12" md="6">
                  <v-text-field
                    :label="$t('profile.main.form.name')"
                    v-model="form.person.name"
                    outlined
                    required
                    :maxlength="255"/>
                </v-col>

                <!-- Position -->
                <v-col cols="12" class="mt-2">
                  <v-text-field
                    outlined
                    :label="$t('settings.fm-accounts.labels.position')"
                    maxlength="100"
                    v-model="form.position" />
                </v-col>

                <!-- Role -->
                <v-col cols="12" class="mt-2">
                  <v-select
                    :items="$t('constants.membersRoles.list')"
                    :label="$t('settings.fm-accounts.labels.user.role')"
                    v-model="form.user.role"
                    outlined />
                </v-col>

                <!-- Lang -->
                <v-col cols="12">
                  <v-select
                    outlined
                    v-model="form.preferences.lang"
                    :label="$t('profile.main.form.lang')"
                    :items="$t('constants.adminPlatformLanguages.list')" />
                </v-col>

              </v-row>

            </v-form>
          </v-col>

          <v-col cols="12">
            <AlertBar />
            <ButtonBarFormDialog
              class="mt-5"
              :isLoadingBtnSave="isLoadingBtn.save"
              v-on:cancel="close"
              v-on:submit="customSubmit"/>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>

</template>

<script>
  // Mixins
  import dialogMixin from "@/mixins/dialog"

  export default {
    name: "ProfileMainDialog",

    components: {
      ButtonBarFormDialog: () => import("@/components/Common/Buttons/ButtonBarFormDialog"),
      AlertBar: () => import("@/components/Common/Bars/AlertBar"),
    },

    mixins: [dialogMixin],

    data() {
      return {
        defaultForm: {
          person: {firstname: "", name: ""},
          preferences: {},
          user: {
          }
        },
        isLoadingBtn: {
          save: false
        }

      }
    },

    mounted() {
    },

    methods: {
      customSubmit() {
        this.isLoadingBtn.save = true;

        this.$http.put(`/fm-accounts/${this.$session.get('id')}`, this.form, {
          headers: {
            Authorization: "Bearer " + this.$session.get('jwt')
          }
        })
        .then(res => {
          this.close(true);
        })
        .catch(err => {
          this.$store.commit("alert/showErrorAxios", err);
        })
        .finally(() => {
          this.isLoadingBtn.save = false;
        })
      },

      onInputLang(val) {
        this.form.preferences.lang = val;
      },
    }
  }
</script>

<style scoped>

</style>